import React from 'react'

function Sample() {
  return (
    <div>
        <h1 style={{marginTop:"100px",marginLeft:"200px",color:"red"}}>hellow world</h1>
      
    </div>
  )
}

export default Sample
