export  const colourOptions = [
    { value: "ocean", label: "Ocean", isFixed: true },
    { value: "blue", label: "Blue",  isDisabled: true },
    { value: "purple", label: "Purple", },
    { value: "red", label: "Red",  isFixed: true },
    { value: "orange", label: "Orange",  },
    { value: "yellow", label: "Yellow",  },
    { value: "green", label: "Green",  },
    { value: "forest", label: "Forest", },
    { value: "slate", label: "Slate", },
    { value: "silver", label: "Silver", },
    
  ];
  
//   const flavourOptions = [
//     { value: "vanilla", label: "Vanilla", rating: "safe" },
//     { value: "chocolate", label: "Chocolate", rating: "good" },
//     { value: "strawberry", label: "Strawberry", rating: "wild" },
//     { value: "salted-caramel", label: "Salted Caramel", rating: "crazy" },
//   ];
  
//   const optionLength = [
//     { value: 1, label: "general" },
//     {
//       value: 2,
//       label:
//         "Evil is the moment when I lack the strength to be true to the Good that compels me.",
//     },
//     {
//       value: 3,
//       label:
//         "It is now an easy matter to spell out the ethic of a truth: 'Do all that you can to persevere in that which exceeds your perseverance. Persevere in the interruption. Seize in your being that which has seized and broken you.",
//     },
//   ];
  
//   const dogOptions = [
//     { id: 1, label: "Chihuahua" },
//     { id: 2, label: "Bulldog" },
//     { id: 3, label: "Dachshund" },
//     { id: 4, label: "Akita" },
//   ];
  
//   // let bigOptions = [];
//   // for (let i = 0; i < 10000; i++) {
//   // 	bigOptions = bigOptions.concat(colourOptions);
//   // }
  
//   const groupedOptions = [
//     {
//       label: "Colours",
//       options: colourOptions,
//     },
//     {
//       label: "Flavours",
//       options: flavourOptions,
//     },
//   ];
  