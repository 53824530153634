// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Hachi+Maru+Pop&family=Mochiy+Pop+One&family=Poppins:wght@100&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* @import url('https://fonts.googleapis.com/css2?family=Lato&display=swap'); */\n/* @import url(\"https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap\"); */\n\n* {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n  font-family: 'Lato', sans-serif;\n}\n\n.home,\n.reports,\n.products {\n  display: flex;\n  height: 100vh;\n  align-items: center;\n  justify-content: center;\n  font-size: 3rem;\n}\n", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,+EAA+E;AAC/E,uGAAuG;;AAGvG;EACE,sBAAsB;EACtB,SAAS;EACT,UAAU;EACV,+BAA+B;AACjC;;AAEA;;;EAGE,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB","sourcesContent":["/* @import url('https://fonts.googleapis.com/css2?family=Lato&display=swap'); */\n/* @import url(\"https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap\"); */\n@import url('https://fonts.googleapis.com/css2?family=Hachi+Maru+Pop&family=Mochiy+Pop+One&family=Poppins:wght@100&display=swap');\n\n* {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n  font-family: 'Lato', sans-serif;\n}\n\n.home,\n.reports,\n.products {\n  display: flex;\n  height: 100vh;\n  align-items: center;\n  justify-content: center;\n  font-size: 3rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
