import React from 'react'
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import DashboardPage1 from './DashBoardTopLayer/DashboardPage1';

function New() {
  return (


    
    <div>
      <br/>
      <br/>
        <div
        style={{
          position: "absolute",
        //  top: "10%",
         left: "10%",
          fontSize: "16px",
          fontWeight: "600",
        }}
      >
        Chillers
      </div>
     <div
        style={{
          position: "absolute",
          // top: "7%",
          //left: "",
          marginLeft:"77%",
          //marginRight:"80%",
          width: "266px",
          height: "67px",
          fontSize: "14px",
          color: "#fbfbfb",
          fontFamily: "Inter",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            borderRadius: "10px",
            background: "radial-gradient(50% 50% at 50% 50%, #7381ff, #62c6ff)",
            boxShadow: "0px 4px 28.3px rgba(0, 0, 0, 0.05)",
            width: "266px",
            height: "67px",
            overflow: "hidden",
          }}
        >
          
          
          
        </div>
        <div
          style={{
            position: "absolute",
            top: "12px",
            left: "14px",
            width: "193px",
            height: "47px",
            fontSize: "16px",
            color: "#fff",
            fontFamily: "Poppins",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              lineHeight: "108%",
              fontWeight: "600",
            }}
          >
            Total Cooling of the day
          </div>
          <div
            style={{
              position: "absolute",
              top: "21px",
              left: "1px",
              lineHeight: "108%",
              fontWeight: "600",
              fontSize: "24px",
            }}
          >
            <span>{`13959 `}</span>
            <span style={{ fontSize: "16px" }}>TR</span>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          // top: "20%",
          left: "60%",
          width: "457px",
          height: "268px",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "457px",
            height: "268px",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              fontWeight: "600",
            }}
          >
            E Block
          </div>
          <div
            style={{
              position: "absolute",
              top: "20px",
              left: "0px",
              width: "457px",
              height: "181px",
              fontSize: "10px",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "0px",
                left: "203px",
                fontWeight: "600",
              }}
            >
              Evaporator
            </div>
            <div
              style={{
                position: "absolute",
                top: "166px",
                left: "203px",
                fontWeight: "600",
              }}
            >
              Condensor
            </div>

            {/* --------------- */}

            <div
              style={{
                position: "absolute",
                 top: "20px",
                left: "0px",
                width: "457px",
                height: "141px",
                fontSize: "14px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "40px",
                  width: "377px",
                  height: "141px",
                  fontSize: "12px",
                }}
              >
                
                <div
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "45px",
                    borderRadius: "10px",
                    backgroundColor: "#f2f2f2",
                    border: "0.5px solid #b1b1b1",
                    boxSizing: "border-box",
                    width: "287px",
                    height: "141px",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "19.67px",
                    left: "68.52px",
                    width: "51.68px",
                    height: "100.73px",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      width: "51.68px",
                      height: "100.73px",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        borderRadius: "10px",
                        backgroundColor: "#ff7338",
                        width: "51.68px",
                        height: "100.73px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "38.91px",
                      left: "15.4px",
                      width: "27px",
                      height: "25.59px",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        fontWeight: "600",
                      }}
                    >
                      4.06
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "14.59px",
                        left: "1.91px",
                        fontSize: "10px",
                        lineHeight: "108%",
                      }}
                    >
                      COP
                    </div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "10.22px",
                      left: "5.28px",
                      fontSize: "10px",
                      lineHeight: "108%",
                    }}
                  >
                    C1
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "19.67px",
                    left: "130.94px",
                    width: "51.68px",
                    height: "100.73px",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      width: "51.68px",
                      height: "100.73px",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        borderRadius: "10px",
                        backgroundColor: "#ff7338",
                        width: "51.68px",
                        height: "100.73px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "38.91px",
                      left: "16.21px",
                      width: "23px",
                      height: "25.59px",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        fontWeight: "600",
                      }}
                    >
                      4.12
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "14.59px",
                        left: "0.64px",
                        fontSize: "10px",
                        lineHeight: "108%",
                      }}
                    >
                      COP
                    </div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "10.22px",
                      left: "5.39px",
                      fontSize: "10px",
                      lineHeight: "108%",
                    }}
                  >
                    C2
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "20.48px",
                    left: "193.36px",
                    width: "51.68px",
                    height: "100px",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      width: "51.68px",
                      height: "100px",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        borderRadius: "10px",
                        backgroundColor: "#ff7338",
                        width: "51.68px",
                        height: "100px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "38.91px",
                      left: "15.4px",
                      width: "26px",
                      height: "25.59px",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        fontWeight: "600",
                      }}
                    >
                      3.88
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "14.59px",
                        left: "1.91px",
                        fontSize: "10px",
                        lineHeight: "108%",
                      }}
                    >
                      COP
                    </div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "10.08px",
                      left: "5.5px",
                      fontSize: "10px",
                      lineHeight: "108%",
                    }}
                  >
                    C3
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "19.67px",
                    left: "256.6px",
                    width: "51.68px",
                    height: "100.73px",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      width: "51.68px",
                      height: "100.73px",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        borderRadius: "10px",
                        backgroundColor: "#d5cecc",
                        width: "51.68px",
                        height: "100.73px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "38.91px",
                      left: "17.02px",
                      width: "22px",
                      height: "25.59px",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        left: "4.47px",
                        fontWeight: "600",
                      }}
                    >
                      0
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "14.59px",
                        left: "0px",
                        fontSize: "10px",
                        lineHeight: "108%",
                      }}
                    >
                      COP
                    </div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "10.22px",
                      left: "4.81px",
                      fontSize: "10px",
                      lineHeight: "108%",
                    }}
                  >
                    C4
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "30px",
                    left: "0px",
                    backgroundColor: "#f2f2f2",
                    width: "46px",
                    height: "25px",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "91px",
                    left: "0px",
                    backgroundColor: "#f2f2f2",
                    width: "46px",
                    height: "24px",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "30px",
                    left: "331px",
                    backgroundColor: "#f2f2f2",
                    width: "46px",
                    height: "25px",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "91px",
                    left: "331px",
                    backgroundColor: "#f2f2f2",
                    width: "46px",
                    height: "24px",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "34px",
                    left: "19px",
                    fontWeight: "600",
                    color: "#000",
                  }}
                >
                  In
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "94px",
                    left: "19px",
                    fontWeight: "600",
                    color: "#000",
                  }}
                >
                  In
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "34px",
                    left: "344px",
                    fontWeight: "600",
                    color: "#000",
                  }}
                >
                  Out
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "94px",
                    left: "344px",
                    fontWeight: "600",
                    color: "#000",
                  }}
                >
                  Out
                </div>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "32px",
                  left: "5px",
                  fontWeight: "600",
                }}
              >
                8 °C
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "92px",
                  left: "0px",
                  fontWeight: "600",
                }}
              >
                10 °C
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "32px",
                  left: "422px",
                  fontWeight: "600",
                }}
              >
                8 °C
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "92px",
                  left: "422px",
                  fontWeight: "600",
                }}
              >
                10 °C
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "223px",
              left: "8px",
              width: "441px",
              height: "45px",
              fontSize: "14px",
              color: "#5a5a5a",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "195px",
                height: "45px",
              }}
            >
              <div style={{ position: "absolute", top: "0px", left: "0px" }}>
                Evaporator Flowrate (m3/h)
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "21px",
                  left: "0px",
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#000",
                }}
              >
                552
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                top: "0px",
                left: "245px",
                width: "196px",
                height: "45px",
              }}
            >
              <div style={{ position: "absolute", top: "0px", left: "0px" }}>
                Condensor Flowrate (m3/h)
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "21px",
                  left: "0px",
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#000",
                }}
              >
                1231
              </div>
            </div>
          </div>
        </div>
       
      </div>
      {/* -------------- */}
      <br/>
      <div
        style={{
          position: "absolute",
          // top: "20%",
          left: "10%",
          width: "457px",
          height: "268px",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "457px",
            height: "268px",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              fontWeight: "600",
            }}
          >
            D Block
          </div>
          <div
            style={{
              position: "absolute",
              top: "20px",
              left: "0px",
              width: "457px",
              height: "181px",
              fontSize: "10px",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "0px",
                left: "203px",
                fontWeight: "600",
              }}
            >
              Evaporator
            </div>
            <div
              style={{
                position: "absolute",
                top: "166px",
                left: "203px",
                fontWeight: "600",
              }}
            >
              Condensor
            </div>
            <div
              style={{
                position: "absolute",
                top: "20px",
                left: "0px",
                width: "457px",
                height: "141px",
                fontSize: "14px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "40px",
                  width: "377px",
                  height: "141px",
                  fontSize: "12px",
                }}
              >
                <img
                  style={{
                    position: "absolute",
                    top: "30px",
                    left: "0px",
                    width: "47px",
                    height: "25px",
                    objectFit: "cover",
                  }}
                  alt=""
                  src="/rectangle-19@2x.png"
                />
                <img
                  style={{
                    position: "absolute",
                    top: "30px",
                    left: "330px",
                    width: "47px",
                    height: "25px",
                    objectFit: "contain",
                  }}
                  alt=""
                  src="/rectangle-21@2x.png"
                />
                <img
                  style={{
                    position: "absolute",
                    top: "91px",
                    left: "0px",
                    width: "47px",
                    height: "24px",
                    objectFit: "cover",
                  }}
                  alt=""
                  src="/rectangle-20@2x.png"
                />
                <img
                  style={{
                    position: "absolute",
                    top: "91px",
                    left: "330px",
                    width: "47px",
                    height: "24px",
                    objectFit: "contain",
                  }}
                  alt=""
                  src="/rectangle-22@2x.png"
                />
                <div
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "45px",
                    borderRadius: "10px",
                    backgroundColor: "#f2f2f2",
                    border: "0.5px solid #b1b1b1",
                    boxSizing: "border-box",
                    width: "287px",
                    height: "141px",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "19.67px",
                    left: "68.52px",
                    width: "51.68px",
                    height: "100.73px",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      width: "51.68px",
                      height: "100.73px",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        borderRadius: "10px",
                        backgroundColor: "#ff7338",
                        width: "51.68px",
                        height: "100.73px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "38.91px",
                      left: "15.4px",
                      width: "27px",
                      height: "25.59px",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        fontWeight: "600",
                      }}
                    >
                      4.06
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "14.59px",
                        left: "1.91px",
                        fontSize: "10px",
                        lineHeight: "108%",
                      }}
                    >
                      COP
                    </div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "10.22px",
                      left: "5.28px",
                      fontSize: "10px",
                      lineHeight: "108%",
                    }}
                  >
                    C1
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "19.67px",
                    left: "130.94px",
                    width: "51.68px",
                    height: "100.73px",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      width: "51.68px",
                      height: "100.73px",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        borderRadius: "10px",
                        backgroundColor: "#ff7338",
                        width: "51.68px",
                        height: "100.73px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "38.91px",
                      left: "16.21px",
                      width: "23px",
                      height: "25.59px",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        fontWeight: "600",
                      }}
                    >
                      4.12
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "14.59px",
                        left: "0.64px",
                        fontSize: "10px",
                        lineHeight: "108%",
                      }}
                    >
                      COP
                    </div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "10.22px",
                      left: "5.39px",
                      fontSize: "10px",
                      lineHeight: "108%",
                    }}
                  >
                    C2
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "20.48px",
                    left: "193.36px",
                    width: "51.68px",
                    height: "100px",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      width: "51.68px",
                      height: "100px",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        borderRadius: "10px",
                        backgroundColor: "#ff7338",
                        width: "51.68px",
                        height: "100px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "38.91px",
                      left: "15.4px",
                      width: "26px",
                      height: "25.59px",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        fontWeight: "600",
                      }}
                    >
                      3.88
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "14.59px",
                        left: "1.91px",
                        fontSize: "10px",
                        lineHeight: "108%",
                      }}
                    >
                      COP
                    </div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "10.08px",
                      left: "5.51px",
                      fontSize: "10px",
                      lineHeight: "108%",
                    }}
                  >
                    C3
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "19.67px",
                    left: "256.6px",
                    width: "51.68px",
                    height: "100.73px",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      width: "51.68px",
                      height: "100.73px",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        borderRadius: "10px",
                        backgroundColor: "#d5cecc",
                        width: "51.68px",
                        height: "100.73px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "38.91px",
                      left: "17.02px",
                      width: "22px",
                      height: "25.59px",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        left: "4.47px",
                        fontWeight: "600",
                      }}
                    >
                      0
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "14.59px",
                        left: "0px",
                        fontSize: "10px",
                        lineHeight: "108%",
                      }}
                    >
                      COP
                    </div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "10.22px",
                      left: "4.81px",
                      fontSize: "10px",
                      lineHeight: "108%",
                    }}
                  >
                    C4
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "30px",
                    left: "0px",
                    backgroundColor: "#f2f2f2",
                    width: "46px",
                    height: "25px",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "91px",
                    left: "0px",
                    backgroundColor: "#f2f2f2",
                    width: "46px",
                    height: "24px",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "30px",
                    left: "331px",
                    backgroundColor: "#f2f2f2",
                    width: "46px",
                    height: "25px",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "91px",
                    left: "331px",
                    backgroundColor: "#f2f2f2",
                    width: "46px",
                    height: "24px",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "34px",
                    left: "19px",
                    fontWeight: "600",
                    color: "#000",
                  }}
                >
                  In
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "94px",
                    left: "19px",
                    fontWeight: "600",
                    color: "#000",
                  }}
                >
                  In
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "34px",
                    left: "344px",
                    fontWeight: "600",
                    color: "#000",
                  }}
                >
                  Out
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "94px",
                    left: "344px",
                    fontWeight: "600",
                    color: "#000",
                  }}
                >
                  Out
                </div>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "32px",
                  left: "5px",
                  fontWeight: "600",
                }}
              >
                8 °C
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "92px",
                  left: "0px",
                  fontWeight: "600",
                }}
              >
                10 °C
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "32px",
                  left: "422px",
                  fontWeight: "600",
                }}
              >
                8 °C
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "92px",
                  left: "422px",
                  fontWeight: "600",
                }}
              >
                10 °C
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "223px",
              left: "8px",
              width: "441px",
              height: "45px",
              fontSize: "14px",
              color: "#5a5a5a",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "195px",
                height: "45px",
              }}
            >
              <div style={{ position: "absolute", top: "0px", left: "0px" }}>
                Evaporator Flowrate (m3/h)
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "21px",
                  left: "0px",
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#000",
                }}
              >
                552
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                top: "0px",
                left: "245px",
                width: "196px",
                height: "45px",
              }}
            >
              <div style={{ position: "absolute", top: "0px", left: "0px" }}>
                Condensor Flowrate (m3/h)
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "21px",
                  left: "0px",
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#000",
                }}
              >
                1231
              </div>
            </div>
          </div>
        </div>
        </div>

    </div>
  )
}

export default New
